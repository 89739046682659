<template>
  <a-modal :visible="dialogVisible" v-loading="editDialogLoading" @cancel="editDialogClose"
           :mask-closable="false" width="500px" :title="`${editForm.id?'编辑':'新增'}${editForm.pid?'行业':'服务商'}`">
    <div>
      <a-form :model="editForm" ref="editFormRef" :rules="editFormRules">
        <a-form-item v-if="!isEditAmount" name="name" :label="`${editForm.pid?'行业':'服务商'}`">
          <a-input v-model:value="editForm.name"
                   :placeholder="`请输入${editForm.pid?'行业':'服务商'}名称`"></a-input>
        </a-form-item>

        <a-form-item v-if="isEditAmount" label="服务商" name="amountId">
          <a-select v-model:value="editForm.amountId" placeholder="请选择服务商" show-search allow-clear
                    :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"
                    ref="select"
                    @change="amountChange">
            <a-select-option :value="item.id" v-for="item in amountList"
                             :label="item.name"
                             :key="item.id">{{
                item.name
              }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item name="template" :label="`${editForm.pid?'行业':'服务商'}话术`">
          <a-textarea v-model:value="editForm.template"
                      :placeholder="`请输入${editForm.pid?'行业':'服务商'}话术`"></a-textarea>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
            <span class="dialog-footer">
              <a-button @click="editDialogClose">取消</a-button>
              <a-button type="primary" @click="editDialogConfirm" :loading="editDialogLoading"
                        class="md-primary m3-border-radius-s">{{ editForm.id ? '编辑' : '添加' }}</a-button>
            </span>
    </template>
  </a-modal>
</template>

<script>
import {computed, reactive, ref, toRaw, toRefs, watch} from "vue";
import industryRequest, {industryAdd, industryUpdate} from "@/api/settings/industry";
import {ElMessage} from "element-plus";
import _lineService from "@/api/open/LineQualityInspections";

export default {
  name: "addIndustryDialog",
  emits: ['update:dialogVisible', 'confirm'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    industry: {
      type: Object,
      default: {},
    },
  },
  setup(props, context) {
    let {industry} = toRefs(props);

    let editForm = reactive({});
    let amountList = ref([]);
    let editFormRef = ref(null);
    let editDialogLoading = ref(false);

    let resetEditForm = () => {
      //置空表单
      editForm.id = '';
      // editForm.pid = '';
      // editForm.amountId = '';
      editForm.name = '';
      editForm.template = '';
    }

    let queryIndustryById = async () => {
      if (!editForm.id) {
        return;
      }
      try {
        let res = await industryRequest.queryById({id: editForm.id});
        if (res && res.data) {
          if (editForm.id == res.data.id) {
            editForm.template = res?.data?.template;
          }
        }
      } catch (e) {
        console.log('queryIndustryById err', e.message || e.msg);
      }
    }

    let getAmountList = async () => {
      //服务商列表
      const res = await _lineService._recordService.getAmountList()
      amountList.value = res?.data?.map(item => {
        //editForm.amountId是int类型，res.data返回的是string类型，为了展示匹配，这里转为int类型
        return {name: item.name, id: Number.parseInt(item.id)};
      }) || [];
    }

    //pid为0代表添加服务商
    let isEditAmount = computed(() => editForm.pid == 0);
    watch(industry, async (newV, preV) => {
      console.log('watch industry', newV, preV)
      if (newV) {
        editForm.id = newV.id;
        editForm.pid = newV.pid;
        editForm.name = newV.name;
        editForm.amountId = newV.amountId;
        editForm.template = newV.template;//行业话术，其实后端列表接口是不返回template字段的（查询太慢），所以这时需要请求一次行业详情去拿
        if (!editForm.template || editForm.template.length == 0) {
          //查询行业话术
          await queryIndustryById();
        }
      }
    }, {immediate: true, deep: true})
    watch(isEditAmount, async (newV, preV) => {
      // console.log('isEditAmount', newV, preV, isEditAmount.value)
      if (newV && amountList.value.length == 0) {
        await getAmountList();
      }
    }, {immediate: true})
    let editFormRules = reactive({
      name: [{required: true, message: '请输入行业名称', trigger: ['change', 'blur']},]
    });

    let amountChange = (id) => {
      // console.log('amountChange', id, editForm.amountId)
      let find = amountList.value.find(item => item.id == id);
      if (find) {
        editForm.name = find.name;
      }
    }
    let editDialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let editDialogConfirm = async () => {
      if (isEditAmount.value && !editForm.amountId) {
        ElMessage.error('请选择服务商')
        return;
      }
      let valid = await editFormRef.value?.validate();
      // console.log('editDialogConfirm', editForm, valid)
      // return;
      if (valid) {
        editDialogLoading.value = true;
        let res;
        try {
          if (editForm.id != null && editForm.id != '') {
            res = await industryUpdate(toRaw(editForm))
          } else {
            res = await industryAdd(toRaw(editForm))
          }
        } catch (e) {
          console.log('editDialogConfirm err', e)
        }

        editDialogLoading.value = false;
        if (res?.code == 200) {
          ElMessage.success(editForm.id ? '编辑成功' : '添加成功')
          //id也有可能是0
          if (res.data || res.data == 0) {
            editForm.id = res.data;//返回id
          }
          if (editForm.id) {
            context.emit('confirm', toRaw(editForm));
          }
          context.emit('update:dialogVisible', false);
          resetEditForm();//置空表单
          // editFormRef.value?.resetFields()
        } else {
          ElMessage.error(res?.message || res?.msg || '添加失败')
        }

      }
    }

    return {
      editForm, editFormRules, editFormRef, editDialogLoading,
      isEditAmount, amountList, amountChange,
      editDialogClose, editDialogConfirm,
    }
  },
}
</script>

<style scoped>

</style>